var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Fotos")])]),_c('template',{slot:"area-titulo"},[_c('h4',[_vm._v("Fotos")])])],2),_c('div',{staticClass:"container",attrs:{"id":"content-page"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"p-0 col-12"},_vm._l((_vm.items),function(item,index){return _c('b-card',{key:index,staticClass:"mb-4",attrs:{"no-body":"","title":item.titulo}},[_c('b-card-title',{staticClass:"px-2 my-4"},[_c('h4',{staticClass:"text-uppercase text-secondary",style:({
                '--color-secondary': _vm.config.disenio.datos.color_secundario,
              })},[_vm._v(" "+_vm._s(item.titulo)+" ")])]),_c('div',{staticClass:"mb-3 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"px-2 btn-group"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.publicado))])])]),_c('div',[_c('b-carousel',{attrs:{"id":"carousel-example-generic","controls":"","fade":"","indicators":"","background":"#ababab","interval":10000,"img-height":"720"}},_vm._l((item.galeria_item),function(item_galeria,index){return _c('b-carousel-slide',{key:index,attrs:{"img-src":item_galeria.imagen},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item_galeria.imagen),expression:"item_galeria.imagen"}],staticClass:"img-fluid",attrs:{"alt":item_galeria.imagen}})]},proxy:true}],null,true)})}),1),_c('div',{staticClass:"px-2 mt-3"},[_c('small',{staticClass:"text-muted",domProps:{"innerHTML":_vm._s(item.descripcion)}})])],1)],1)}),1)]),_c('div',{staticClass:"mt-5 mb-5 text-center row",attrs:{"id":"navegacion"}},[_c('b-pagination-nav',{staticClass:"my-0",style:({
          '--background-color-secondary':
            _vm.config.disenio.datos.color_secundario,
          '--background-color-tertiary': _vm.config.disenio.datos.color_terciario,
        }),attrs:{"use-router":"","link-gen":_vm.linkGen,"number-of-pages":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }