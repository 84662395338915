<template>
  <div>
    <!--<PageHeader :titulo="titulo"/>-->
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Fotos</span>
      </template>
      <template slot="area-titulo">
        <h4>Fotos</h4>
      </template>
    </titulo-seccion>
    <div id="content-page" class="container">
      <div class="row">
        <div class="p-0 col-12">
          <b-card
            no-body
            v-for="(item, index) in items"
            :key="index"
            class="mb-4"
            :title="item.titulo"
          >
            <b-card-title class="px-2 my-4"
              ><h4
                class="text-uppercase text-secondary"
                :style="{
                  '--color-secondary': config.disenio.datos.color_secundario,
                }"
              >
                {{ item.titulo }}
              </h4></b-card-title
            >
            <div class="mb-3 d-flex justify-content-between align-items-center">
              <div class="px-2 btn-group">
                <small class="text-muted">{{ item.publicado }}</small>
                <!--<small class="text-muted">{{item.updated_at}}</small>-->
              </div>
            </div>
            <div>
              <b-carousel
                id="carousel-example-generic"
                controls
                fade
                indicators
                background="#ababab"
                :interval="10000"
                img-height="720"
              >
                <!-- <b-carousel-slide 
                                        v-for="(item_galeria, index) in item.galeria_item" :key="index"
                                        :img-src="item_galeria.imagen" 
                                    /> -->
                <b-carousel-slide
                  v-for="(item_galeria, index) in item.galeria_item"
                  :key="index"
                  :img-src="item_galeria.imagen"
                >
                  <template #img>
                    <img
                      class="img-fluid"
                      v-lazy="item_galeria.imagen"
                      :alt="item_galeria.imagen"
                    />
                    <!-- <img
                                                class="img-fluid"
                                                v-lazy="item_galeria.imagen"
                                            > -->
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <div class="px-2 mt-3">
                <small class="text-muted" v-html="item.descripcion"></small>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 text-center row">
        <b-pagination-nav
          use-router
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalRows"
          :per-page="perPage"
          first-number
          last-number
          align="center"
          class="my-0"
          :style="{
            '--background-color-secondary':
              config.disenio.datos.color_secundario,
            '--background-color-tertiary': config.disenio.datos.color_terciario,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
//import PageHeader from '/src/components/PageHeader.vue';
import { BCard, BCarousel, BCarouselSlide, BCardTitle } from "bootstrap-vue";
import TituloSeccion from "@/views/secciones/TituloSeccion.vue";
import { BPaginationNav } from "bootstrap-vue";

export default {
  components: {
    //PageHeader,
    BCard,
    BCarousel,
    BCarouselSlide,
    BCardTitle,
    TituloSeccion,
    BPaginationNav,
  },
  directives: {},
  data() {
    return {
      titulo: "Galeria",
      items: [],
      buscar: "",
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
    };
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Galeria",
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [
      { name: "description", content: "Galeria de fotos de la institucion" },
    ],
  },
  created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.currentPage = 1;
      this.listarGaleria();
    } else {
      this.currentPage = this.$route.query.page;
      this.listarGaleria();
    }
  },
  watch: {
    $route: function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.currentPage = 1;
        this.listarGaleria();
      } else {
        this.currentPage = this.$route.query.page;
        this.listarGaleria();
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    listarGaleria() {
      var url = "/admin/galeria?page=" + this.currentPage;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = res.data.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-aside {
  display: flex;
  margin-right: 1rem;
}
.align-self-start {
  align-self: flex-start !important;
}
.media-list .media {
  padding: 1.25rem;
  margin-top: 0;
}
.media-bordered .media:not(:first-child) {
  border-top: 1px solid #ebe9f1;
}
.card-title {
  font-size: 14px;
}
.sr-only {
  display: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
</style>
